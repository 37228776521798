import { useEffect, useState } from "react";
import { CustomerListSummary, PagedResult } from "../../models/Admin";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableBody, TableCellLayout } from "@fluentui/react-components";
import { Link } from "react-router-dom";
import * as api from "../../utils/api";
import { Button } from "react-bootstrap";

export const AdminCustomerList = () => {
    const [customers, setCustomers] = useState<PagedResult<CustomerListSummary>>({ data: [], pageCount: -1, pageNumber: 1, total: 0 });
    const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);
    const [counter, setCounter] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const pageSize = 25;

    const customersQueryStringParts = [];
    customersQueryStringParts.push(`pageIndex=${pageIndex}`);
    customersQueryStringParts.push(`pageSize=${pageSize}`);
    const customersQueryString = `?${customersQueryStringParts.join("&")}`;

    useEffect(() => {
        async function execute() {
            try {
                setIsCallingApi(true);
                setError(null);
                setCustomers(await api.get<PagedResult<CustomerListSummary>>(`api/admin/customers${customersQueryString}`, { jsonReviver: api.dateReviver }));
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsCallingApi(false);
            }
        }
        execute();
    }, [counter, customersQueryString, pageIndex]);

    if (error) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / customers
                </h1>
                <div>Error: {error.message}</div>
            </>
        );
    }
    if (isCallingApi) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / customers
                </h1>
                <div>loading...</div>
            </>
        );
    }

    async function populateCustomerB2cDetails() {
        try {
            setIsCallingApi(true);
            setError(null);
            await api.post(`api/admin/customers/populate-b2c-details`, null);
            setCounter(counter + 1);
        } catch (e) {
            setError(e as Error);
        } finally {
            setIsCallingApi(false);
        }
    }

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / customers
            </h1>
            {customers.pageCount === -1 ?
                <div>Loading...</div> : <>
                    <Table className="table">
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Name</TableHeaderCell>
                                <TableHeaderCell>Email</TableHeaderCell>
                                <TableHeaderCell>Customer ID</TableHeaderCell>
                                <TableHeaderCell>User ID</TableHeaderCell>
                                <TableHeaderCell>StripeId</TableHeaderCell>
                                <TableHeaderCell>Sign In Using</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {customers?.data?.map((customer) => (
                                <TableRow key={customer.id}>
                                    <TableCell>
                                        <TableCellLayout appearance="primary">
                                            <Link to={`/admin/customers/${customer.id}`}>{customer.name}</Link>
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout>{customer.email}</TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout>{customer.id}</TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout>{customer.userId}</TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <a href={customer.link}>{customer.stripeId}</a>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout>{customer.b2cSignInDescription}</TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/admin/bookings?customerId=${customer.id}`}>bookings...</Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className="pager-controls">
                        <Button
                            onClick={() => {
                                if (pageIndex > 0) {
                                    setPageIndex(pageIndex - 1);
                                }
                            }}
                            disabled={pageIndex === 0}
                        >
                            &lt; Previous
                        </Button>
                        <span className="current-page">
                            Page {customers.pageNumber} of {customers.pageCount}
                        </span>
                        <Button
                            onClick={() => {
                                if (pageIndex < customers.pageCount - 1) {
                                    setPageIndex(pageIndex + 1);
                                }
                            }}
                            disabled={pageIndex >= customers.pageCount - 1}
                        >
                            Next &gt;
                        </Button>
                    </div>
                </>}

            <Button onClick={populateCustomerB2cDetails}>Populate B2C Details</Button>
        </>
    );
};
