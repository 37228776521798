import { useEffect, useState } from "react";
import { BookingListSummary, PagedResult } from "../../models/Admin";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableBody, TableCellLayout, Button } from "@fluentui/react-components";
import { Link, useSearchParams } from "react-router-dom";
import * as api from "../../utils/api";
import { getHumanFriendlyDelta } from "../../utils/friendlyDates";

export const AdminBookingList = () => {
    const [searchParams] = useSearchParams();
    const [bookings, setBookings] = useState<BookingListSummary[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const pageSize = 25;
    const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);

    const eventId = searchParams.get("eventId");
    const customerId = searchParams.get("customerId");

    let bookingsQueryStringParts = [];
    bookingsQueryStringParts.push(`pageIndex=${pageIndex}`);
    bookingsQueryStringParts.push(`pageSize=${pageSize}`);
    if (eventId) {
        bookingsQueryStringParts.push(`eventId=${eventId}`);
    }
    if (customerId) {
        bookingsQueryStringParts.push(`customerId=${customerId}`);
    }
    const bookingsQueryString = `?${bookingsQueryStringParts.join("&")}`;
    const totalPages = Math.ceil(totalRows / pageSize);

    useEffect(() => {
        async function execute() {
            try {
                setIsCallingApi(true);
                setError(null);
                const result = await api.get<PagedResult<BookingListSummary>>(`api/admin/bookings${bookingsQueryString}`, {
                    jsonReviver: api.dateReviver,
                });
                setBookings(result.data);
                setTotalRows(result.total);
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsCallingApi(false);
            }
        }
        execute();
    }, [bookingsQueryString]);

    if (error) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / bookings
                </h1>
                <div>Error: {error.message}</div>
            </>
        );
    }
    if (isCallingApi) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / bookings
                </h1>
                <div>loading...</div>
            </>
        );
    }
    function showFriendlyDate(date: Date) {
        const friendlyDate = getHumanFriendlyDelta(date);
        if (friendlyDate) {
            return <>({friendlyDate})</>;
        }
        return <></>;
    }

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / bookings
            </h1>
            <Table className="table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Booking Reference</TableHeaderCell>
                        <TableHeaderCell>Course</TableHeaderCell>
                        <TableHeaderCell>Booked</TableHeaderCell>
                        <TableHeaderCell>Booking Name</TableHeaderCell>
                        <TableHeaderCell>Booking Email</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {bookings?.map((booking) => (
                        <TableRow key={booking.id}>
                            <TableCell>
                                <TableCellLayout appearance="primary">
                                    <Link to={`/admin/bookings/${booking.id}`}>{booking.id}</Link>
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>
                                    <Link to={`/admin/events/${booking.eventSlug}`}>{booking.eventTitle}</Link>
                                    <br />(<Link to={`/admin/events/${booking.eventSlug}/${booking.optionId}`}>{booking.optionTitle}</Link>)
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                {booking.bookingDate.toLocaleString()} {showFriendlyDate(booking.bookingDate)}
                            </TableCell>
                            <TableCell>
                                <Link to={`/admin/customers/${booking.customerId}`}>{booking.customerName}</Link>
                            </TableCell>
                            <TableCell>{booking.customerEmail}</TableCell>
                            <TableCell>
                                {booking.status}{" "}
                                {booking.statusReason && (
                                    <>
                                        <br />
                                        {`(${booking.statusReason})`}
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className="pager-controls">
                <Button
                    onClick={() => {
                        if (pageIndex > 0) {
                            setPageIndex(pageIndex - 1);
                        }
                    }}
                    disabled={pageIndex === 0}
                >
                    &lt; Previous
                </Button>
                <span className="current-page">
                    Page {pageIndex + 1} of {totalPages}
                </span>
                <Button
                    onClick={() => {
                        if (pageIndex < totalPages - 1) {
                            setPageIndex(pageIndex + 1);
                        }
                    }}
                    disabled={pageIndex >= totalPages - 1}
                >
                    Next &gt;
                </Button>
            </div>
        </>
    );
};
