import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { BrowserRouter } from 'react-router-dom';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// // Default to using the first account if no account is active on page load
// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//     // Account selection logic is app dependent. Adjust as needed for different use cases.
//     const activeAccount = msalInstance.getActiveAccount();
//     msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
// }

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// // Listen for sign-in event and set active account
// msalInstance.addEventCallback((event) => {
//     if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload?.account) {
//         const account = event?.payload?.account;
//         msalInstance.setActiveAccount(account);
//     }
// });


// const root = ReactDOM.createRoot(
//     document.getElementById('root') as HTMLElement
// );
// root.render(
//     <React.StrictMode>
//     <App />
//     </React.StrictMode>
// );

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <FluentProvider theme={teamsLightTheme}>
                <App instance={msalInstance} />
            </FluentProvider>
        </BrowserRouter>
    </React.StrictMode>
);