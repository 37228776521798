import React from "react";
import { NavigationBar } from "./NavigationBar";

function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
function setCookie(cname: string, cvalue: string) {
    const now = new Date();
    now.setTime(now.getTime() + 90 * 24 * 60 * 60 * 1000); // 90 days from now
    const expires = "expires=" + now.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function hasConsentedCookie() {
    let consented = getCookie("cookie-consented");
    console.log("consented: " + consented);
    return !!consented;
}
function setConsentedCookie() {
    setCookie("cookie-consented", "true");
}

export const PageLayout = (props: any) => {
    const [consented, setConsentedState] = React.useState(hasConsentedCookie());
    const setConsented = () => {
        setConsentedCookie();
        setConsentedState(true);
    };
    const cookieBanner = consented ? (
        <></>
    ) : (
        <>
            <div id="cookie-banner">
                <div className="cookie-banner-content">
                    <div>This site uses a small number of cookies such as to enable signing in and to aid in tracking errors.</div>
                    <div>By continuing on this site you consent to the use of cookies.</div>
                    <button id="cookie-accept-button" className="btn btn-primary btn-lg" onClick={() => setConsented()}>
                        Accept cookies
                    </button>
                </div>
            </div>
        </>
    );

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            <NavigationBar />
            <div className="container body-content">
                {props.children}
                <div id="response"></div>
                <footer>
                    &copy; 2023 - <a href="https://emilieleeks.com">emilieleeks.com</a>
                </footer>
            </div>
            {cookieBanner}
        </>
    );
};
