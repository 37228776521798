import { useEffect, useState } from "react";
import { ResourcePack } from "../../models/Admin";
import { Button, Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableBody, TableCellLayout } from "@fluentui/react-components";
import { Edit32Regular } from "@fluentui/react-icons";
import { Link, useNavigate } from "react-router-dom";
import * as api from "../../utils/api";

export const AdminResourcePackList = () => {
    const navigate = useNavigate();
    const [resourcePacks, setResourePacks] = useState<ResourcePack[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);

    useEffect(() => {
        async function execute() {
            try {
                setIsCallingApi(true);
                setError(null);
                setResourePacks(await api.get<ResourcePack[]>("api/admin/resource-packs"));
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsCallingApi(false);
            }
        }
        execute();
    }, []);

    if (error) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / resource-packs
                </h1>
                <div>Error: {error.message}</div>
            </>
        );
    }
    if (isCallingApi) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / resource-packs
                </h1>
                <div>loading...</div>
            </>
        );
    }
    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / resource-packs
            </h1>
            <Table className="table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>name</TableHeaderCell>
                        <TableHeaderCell>description</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {resourcePacks?.map((resourcePack) => (
                        <TableRow key={resourcePack.id}>
                            <TableCell>
                                <TableCellLayout appearance="primary">{resourcePack.name}</TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>{resourcePack.description}</TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <Button
                                    icon={<Edit32Regular />}
                                    onClick={() => navigate(`/admin/resource-packs/${resourcePack.id}`)}
                                ></Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button onClick={() => navigate("/admin/resource-packs/$new")}>New Resource Pack</Button>
        </>
    );
};
