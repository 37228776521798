import { Link } from "react-router-dom";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const AdminHome = () => {
    return (
        <>
            <h1>admin</h1>
            <ul className="admin-link-list">
                <li>
                    <Link to="/admin/resource-packs">Resource Packs</Link>
                </li>
                <li>
                    <Link to="/admin/events">Events</Link>
                </li>
                <li>
                    <Link to="/admin/bookings">Bookings</Link>
                </li>
                <li>
                    <Link to="/admin/customers">Customers</Link>
                </li>
                <li>
                    <Link to="/admin/audit-entries">Audit Entries</Link>
                </li>
                {/* TODO: Database migrations */}
            </ul>
        </>
    );
};
