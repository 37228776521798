import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Button, Navbar } from 'react-bootstrap';
import { loginRequest } from '../authConfig';

const SignInSignOutButton = () => {
	const { instance } = useMsal();
	const activeAccount = instance ? instance.getActiveAccount() : null;
	return (
		<>
			<AuthenticatedTemplate>
				<span>{activeAccount?.name}</span>
				<Button variant="secondary" onClick={() => instance.logout()} className="ml-auto">Sign Out</Button>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Button variant="secondary" onClick={() => instance.loginRedirect(loginRequest)} className="ml-auto">Sign In</Button>
			</UnauthenticatedTemplate>
		</>
	);
};


export const NavigationBar = () => {

	const { instance } = useMsal();

	const activeAccount = instance ? instance.getAllAccounts()[0] : null;

	// TODO load via API? (needs caching!)
	const showAdmin = activeAccount && (["stuart@leeks.net", "emilie@leeks.net", "emilie@emilieleeks.com", "emilie@journeys-in-parenting.com", "james@leeks.net"].indexOf(activeAccount.username) >= 0);

	return (
		<>
			<Navbar bg="primary" variant="dark" id="nav">
				<div className="container">
					<div className="navbar-header">
						<a href="https://emilieleeks.com" className="navbar-brand banner-image">
							<img className="banner" src="/logo512.png" alt="Logo for Emilie Leeks and Journeys in Parenting" />
						</a>
						<a className="navbar-brand" href="/">my.emilieleeks.com</a>
					</div>
				</div>
				<AuthenticatedTemplate>
					<a className="navbar-brand" href="https://bookings.emilieleeks.com/my-account">Your bookings</a>
				</AuthenticatedTemplate>
				{showAdmin ? (<a className="navbar-brand" href="/admin">Admin</a>) : (<></>)}
				<SignInSignOutButton />
			</Navbar>
		</>
	);
};