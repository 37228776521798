import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ResourcePack, Resource, ResourcePackHeading } from "../../models/Admin";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "../../hooks/useBoolean";
import { useDialogStyles } from "../../hooks/useDialogStyles";
import {
    Field,
    Input,
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    Dropdown,
    Option,
} from "@fluentui/react-components";
import * as api from "../../utils/api";
import { ApiValidationError, getEntityWithValidation } from "../../models/ValidatedEntity";

type ResourceNew = Omit<Resource, "id">;

export const AdminResourceEdit = () => {
    const { packId, resourceId } = useParams() as { packId: string; resourceId: string };
    const navigate = useNavigate();
    const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
        useBoolean(false);
    const [isDeleteConfirmationDialogOpen, { setTrue: showDeleteConfirmationDialog, setFalse: hideDeleteConfirmationDialog }] =
        useBoolean(false);
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [packName, setPackName] = useState<string | null>(null);
    const [resourceName, setResourceName] = useState<string | null>(null);
    const [resource, setResource] = useState<Resource | null>(null);
    const [resourcePackHeadings, setResourcePackHeadings] = useState<ResourcePackHeading[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);

    const [showSavedBanner, setShowSavedBanner] = useState(false);

    useEffect(() => {
        async function execute() {
            setActivityMessage("loading...");
            try {
                const resourcePack = await api.get<ResourcePack>(`api/admin/resource-packs/${packId}`);
                const resource = await api.get<Resource>(`api/admin/resource-packs/${packId}/resources/${resourceId}`);
                setResource(resource);
                setPackName(resourcePack?.name ?? null);
                setResourceName(resource?.name ?? null);

                setResourcePackHeadings(await api.get<ResourcePackHeading[]>(`api/admin/resource-packs/${packId}/headings`));
            } catch (e) {
                setError(e as Error);
            } finally {
                setActivityMessage(null);
            }
        }
        execute();
    }, [packId, resourceId]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        if (!resource) {
            throw new Error("Resource not loaded");
        }
        setActivityMessage("saving...");
        try {
            const { id, ...resourceUpdate } = resource;
            const response = await api.put<ResourceNew, Resource>(
                `api/admin/resource-packs/${packId}/resources/${resourceId}`,
                resourceUpdate
            );
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                setValidationErrors([]);
                setResource(response);
                setShowSavedBanner(true);
                setTimeout(() => setShowSavedBanner(false), 1000);
            }
        } catch (e) {
            setError(e as Error);
        } finally {
            setActivityMessage(null);
        }
    };
    const deleteEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        hideDeleteConfirmationDialog();
        setActivityMessage("deleting...");
        await api.delete(`api/admin/resource-packs/${packId}/resources/${resourceId}`);
        navigate(`/admin/resource-packs/${packId}`);
    };

    const entityWithValidation = resource && getEntityWithValidation(resource, validationErrors);

    const styles = useDialogStyles();

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / <Link to="/admin/resource-packs">resource packs</Link> /{" "}
                <Link to={`/admin/resource-packs/${packId}`}>{packName}</Link> / resources / {resourceName}
            </h1>
            {showSavedBanner && <div className={styles.savedBanner}>Saved!</div>}
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>Error: {error.message}</div>}

            <h2>Resource Pack</h2>
            <div className={styles.root}>
                {entityWithValidation && (
                    <>
                        {entityWithValidation.entityMessage && <div>Errors: {entityWithValidation.entityMessage}</div>}
                        <Field
                            label="Name"
                            hint="Name of the resource"
                            orientation="vertical"
                            required={true}
                            validationMessage={entityWithValidation.propertyMessages.name}
                        >
                            <Input defaultValue={resource.name} onChange={(_, data) => setResource({ ...resource, name: data.value })} />
                        </Field>
                        <Field
                            label="Link"
                            hint="Link for the resource"
                            orientation="vertical"
                            required={true}
                            validationMessage={entityWithValidation.propertyMessages.link}
                        >
                            <Input defaultValue={resource.link} onChange={(_, data) => setResource({ ...resource, link: data.value })} />
                        </Field>
                        <Field
                            label="Order"
                            hint="Sort order for the resource - resources are sorted by their order value (lower numbers first)"
                            orientation="vertical"
                            required={true}
                            validationMessage={entityWithValidation.propertyMessages.order}
                        >
                            <Input
                                defaultValue={resource.order.toString()}
                                type="number"
                                onChange={(_, data) => setResource({ ...resource, order: parseInt(data.value) })}
                            />
                        </Field>
                        <Field
                            label="Heading"
                            hint="The heading to show the resource under"
                            orientation="vertical"
                            className={styles.field}
                            required={false}
                            validationMessage={entityWithValidation.propertyMessages.headingId}
                        >
                            <Dropdown
                                value={resource.headingId ? resourcePackHeadings.find((h) => h.id === resource.headingId)?.name ?? "" : ""}
                                selectedOptions={[resource.headingId?.toString() ?? ""]}
                                onOptionSelect={(_, data) => {
                                    setResource({
                                        ...resource,
                                        headingId: (data.optionValue ?? "") === "" ? null : parseInt(data.optionValue!),
                                    });
                                }}
                            >
                                <Option text="" value="" />
                                {resourcePackHeadings?.map((g) => (
                                    <Option key={g.id} text={g.name} value={g.id.toString()}>
                                        {g.name}
                                    </Option>
                                ))}
                            </Dropdown>
                        </Field>

                        <div className={styles.actions}>
                            <Button appearance="primary" onClick={saveEntity}>
                                Save
                            </Button>
                            <Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
                                Cancel
                            </Button>
                            <Button appearance="secondary" className={styles.dangerButton} onClick={showDeleteConfirmationDialog}>
                                Delete
                            </Button>
                            <Dialog open={isDiscardConfirmationDialogOpen}>
                                <DialogSurface>
                                    <DialogBody>
                                        <DialogTitle>Discard changes?</DialogTitle>
                                        <DialogContent>Are you sure you want to discard any changes?</DialogContent>
                                        <DialogActions>
                                            <Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
                                                Cancel
                                            </Button>
                                            <Button appearance="primary" onClick={() => navigate(`/admin/resource-packs/${packId}`)}>
                                                Discard
                                            </Button>
                                        </DialogActions>
                                    </DialogBody>
                                </DialogSurface>
                            </Dialog>
                            <Dialog open={isDeleteConfirmationDialogOpen}>
                                <DialogSurface>
                                    <DialogBody>
                                        <DialogTitle>Delete Resource?</DialogTitle>
                                        <DialogContent>
                                            WARNING: deleting cannot be undone. Are you sure you want to delete this resource?
                                        </DialogContent>
                                        <DialogActions>
                                            <Button appearance="secondary" onClick={hideDeleteConfirmationDialog}>
                                                Cancel
                                            </Button>
                                            <Button appearance="primary" className={styles.dangerButton} onClick={deleteEntity}>
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </DialogBody>
                                </DialogSurface>
                            </Dialog>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
