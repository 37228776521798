import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Button } from "@fluentui/react-components";

import * as MarkdownIt from 'markdown-it';

import { loginRequest } from '../authConfig';
import { ResourcePack } from '../models/User';
import * as api from "../utils/api";


const md = MarkdownIt.default();


const HomeContent = () => {
	const navigate = useNavigate();
	const [resourcePacks, setResourcePacks] = useState<ResourcePack[]>([]);
	const [error, setError] = useState<Error | null>(null);
	const [isCallingApi, setIsCallingApi] = useState(true);

	useEffect(() => {
		async function execute() {
			try {
				setIsCallingApi(true);
				setResourcePacks(await api.get<ResourcePack[]>("api/resource-packs"))
			}
			catch (e) {
				setError(e as Error);
			}
			finally {
				setIsCallingApi(false);
			}
		}
		execute();
	}, [])


	if (error) {
		return <>
			<div>Error: {error.message}</div>
		</>
	}
	if (isCallingApi) {
		return <>
			<div>loading...</div>
		</>
	}
	if (resourcePacks?.length === 1) {
		// go straight to the resource pack if only one is available
		navigate(`/resource-packs/${resourcePacks[0].id}`);
		return null;
	}

	if ((resourcePacks?.length ?? 0) === 0) {
		return (
			<>
				<div>You don't seem to have access to any resource packs. Please contact <a href="mailto:emilie@emilieleeks.com">emilie@emilieleeks.com</a> if you think you should have resources available here.</div>
				<div>To check or edit your bookings (e.g. change your credit card details), visit the <a href="https://bookings.emilieleeks.com/my-account">bookings site</a>.</div>
			</>
		)
	}

	return (
		<>
			<div>Your available resources are all listed here.</div>
			<div>To check or edit your bookings (e.g. change your credit card details), visit the <a href="https://bookings.emilieleeks.com/my-account">bookings site</a>.</div>
			<div className='user-resource-pack-list'>
				{resourcePacks?.map((resourcePack) => (
					<div key={resourcePack.id} className='user-resource-pack'>
						<div className='title'>
							<a href={`/resource-packs/${resourcePack.id}`}>
								<Button key={resourcePack.id} appearance='primary' size='large' className="">
									{resourcePack.name}
								</Button>
							</a>
						</div>
						<div className='description' dangerouslySetInnerHTML={{ __html: md.render(resourcePack.description ?? "") }}>
						</div>
						<a href={`/resource-packs/${resourcePack.id}`}>Go to resources...</a>
					</div>
				))}
			</div>
		</>
	)
}

export const Home = () => {
	const { instance } = useMsal();

	return (
		<>
			<AuthenticatedTemplate>
				<HomeContent />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<p>This site allows you to access resources for bookings via <a href="https://bookings.emilieleeks.com">bookings.emilieleeks.com</a>.</p>
				<Button appearance="primary" onClick={() => instance.loginRedirect(loginRequest)} className="ml-auto">Sign In</Button>
			</UnauthenticatedTemplate>
		</>
	);
};