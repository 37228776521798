export function getHumanFriendlyDelta(date: Date, referenceDate?: Date) {

	if (!referenceDate) {
		referenceDate = new Date();
	}
	const deltaMilliseconds = referenceDate.valueOf() - date.valueOf();

	if (deltaMilliseconds > 0) {
		// past dates (i.e. date is before referenceDate)
		const deltaSeconds = Math.floor(deltaMilliseconds / 1000);
		const deltaMinutes = Math.floor(deltaSeconds / 60);
		const deltaHours = Math.floor(deltaMinutes / 60);

		const deltaDays = Math.floor(deltaHours / 24);

		if (deltaSeconds < 5) {
			return "just now";
		} else if (deltaSeconds < 60) {
			return deltaSeconds + " seconds ago";
		} else if (deltaMinutes === 1) {
			return "1 minute ago";
		} else if (deltaMinutes < 60) {
			return deltaMinutes + " minutes ago";
		} else if (deltaHours === 1) {
			return "1 hour ago";
		} else if (deltaHours < 24) {
			return deltaHours + " hours ago";
		} else if (deltaDays === 1) {
			return "yesterday";
		} else if (deltaDays < 7) {
			return deltaDays + " days ago";
		} else if (deltaDays < 31) {
			const deltaWeeks = Math.ceil(deltaDays / 7);
			return deltaWeeks + " weeks ago";
		}
		return null;
	} else {
		// future dates (i.e. date is after referenceDate)
		const deltaSeconds = Math.floor(-deltaMilliseconds / 1000);
		const deltaMinutes = Math.floor(deltaSeconds / 60);
		const deltaHours = Math.floor(deltaMinutes / 60);

		const deltaDays = Math.floor(deltaHours / 24);

		if (deltaMinutes === 1) {
			return "in a minute";
		} else if (deltaMinutes < 60) {
			return "in " + deltaMinutes + " minutes";
		} else if (deltaHours === 1) {
			return "in an hour";
		} else if (deltaHours < 24) {
			return "in " + deltaHours + " hours";
		} else if (deltaDays === 1) {
			return "in a day";
		} else if (deltaDays < 7) {
			return "in " + deltaDays + " days";
		} else if (deltaDays < 31) {
			const deltaWeeks = Math.ceil(deltaDays / 7);
			return "in " + deltaWeeks + " weeks";
		}
		return null;
	}
}