import { useEffect, useState } from "react";
import { Customer, ResourcePack } from "../../models/Admin";
import { Table, TableRow, TableCell, TableBody } from "@fluentui/react-components";
import { Link, useParams } from "react-router-dom";
import * as api from "../../utils/api";
import { getHumanFriendlyDelta } from "../../utils/friendlyDates";

export const AdminCustomerShow = () => {
    const { customerId } = useParams() as { customerId: string };
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [resourcePacks, setResourcePacks] = useState<ResourcePack[] | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);

    useEffect(() => {
        async function execute() {
            try {
                setIsCallingApi(true);
                setError(null);
                const tempCustomer = await api.get<Customer>(`api/admin/customers/${customerId}`, { jsonReviver: api.dateReviver });
                setCustomer(tempCustomer);
                setResourcePacks(await api.get<ResourcePack[]>(`api/admin/resource-packs?user=${tempCustomer.userId}`, { jsonReviver: api.dateReviver }));
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsCallingApi(false);
            }
        }
        execute();
    }, [customerId]);

    if (error) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / <Link to="/admin/customers">customers</Link> / {customerId}
                </h1>
                <div>Error: {error.message}</div>
            </>
        );
    }
    if (isCallingApi || !customer) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / <Link to="/admin/customers">customers</Link> / {customerId}
                </h1>
                <div>loading...</div>
            </>
        );
    }
    function showFriendlyDate(date: Date) {
        const friendlyDate = getHumanFriendlyDelta(date);
        if (friendlyDate) {
            return <>({friendlyDate})</>;
        }
        return <></>;
    }
    function showDateAndFriendlyDate(date: Date) {
        return (
            <>
                {date.toLocaleString()} {showFriendlyDate(date)}
            </>
        );
    }

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / <Link to="/admin/customers">customers</Link> / {customerId}
            </h1>
            <Table className="table">
                <TableBody>
                    <TableRow>
                        <TableCell>Customer ID</TableCell>
                        <TableCell>{customer.id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{customer.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{customer.email}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>UserId</TableCell>
                        <TableCell>{customer.userId}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>StripeId</TableCell>
                        <TableCell>
                            <a href={customer.link}>{customer.stripeId}</a>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sign In Using</TableCell>
                        <TableCell>{customer.b2cSignInDescription}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <div>
                <Link to={`/admin/audit-entries?customerId=${customer.id}`}>Go to audit entries...</Link>
            </div>

            <h2>Old Email Addresses</h2>
            {customer.oldEmails.length === 0 ? (
                <>No old email addresses</>
            ) : (
                <>
                    <Table className="table">
                        <TableBody>
                            {customer.oldEmails.map((oldEmail) => (
                                <TableRow key={oldEmail.id}>
                                    <TableCell>{oldEmail.email}</TableCell>
                                    <TableCell>{showDateAndFriendlyDate(oldEmail.changedDate)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}

            <h2>Resource Packs</h2>
            {resourcePacks?.length === 0 ? (
                <>This customer doesn't have any resource packs available</>
            ) : (
                <>
                    <Table className="table">
                        <TableBody>
                            {resourcePacks?.map((resourcePack) => (
                                <TableRow key={resourcePack.id}>
                                    <TableCell>
                                        <Link to={`/admin/resource-packs/${resourcePack.id}`}>{resourcePack.name}</Link>
                                    </TableCell>
                                    <TableCell>{resourcePack.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </>
    );
};
