import { useEffect, useState } from "react";
import { BookingSummary } from "../../models/Admin";
import { Table, TableRow, TableCell, TableHeader, TableBody, TableCellLayout, TableHeaderCell } from "@fluentui/react-components";
import { Link, useParams } from "react-router-dom";
import * as api from "../../utils/api";
import { getHumanFriendlyDelta } from "../../utils/friendlyDates";

export const AdminBookingShow = () => {
    const { bookingId } = useParams() as { bookingId: string };
    const [booking, setBooking] = useState<BookingSummary | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);

    useEffect(() => {
        async function execute() {
            try {
                setIsCallingApi(true);
                setError(null);
                setBooking(await api.get<BookingSummary>(`api/admin/bookings/${bookingId}`, { jsonReviver: api.dateReviver }));
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsCallingApi(false);
            }
        }
        execute();
    }, [bookingId]);

    if (error) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / <Link to="/admin/bookings">bookings</Link> / {bookingId}
                </h1>
                <div>Error: {error.message}</div>
            </>
        );
    }
    if (isCallingApi || !booking) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / <Link to="/admin/bookings">bookings</Link> / {bookingId}
                </h1>
                <div>loading...</div>
            </>
        );
    }
    function showFriendlyDate(date: Date) {
        const friendlyDate = getHumanFriendlyDelta(date);
        if (friendlyDate) {
            return <>({friendlyDate})</>;
        }
        return <></>;
    }
    function showDateAndFriendlyDate(date: Date) {
        return (
            <>
                {date.toLocaleString()} {showFriendlyDate(date)}
            </>
        );
    }

    const additionalRows = [];

    if (booking.stripeError) {
        additionalRows.push(
            <TableRow key="stripe-error">
                <TableCell>Stripe Error</TableCell>
                <TableCell>{booking.stripeError}</TableCell>
            </TableRow>
        );
    } else if (booking.eventOptionType === "one-off") {
        additionalRows.push(
            <TableRow key="base-price">
                <TableCell>Base Price</TableCell>
                <TableCell>&pound;{booking.basePrice.toFixed(2)}</TableCell>
            </TableRow>,
            <TableRow key="coupon">
                <TableCell>Coupon</TableCell>
                <TableCell>
                    {booking.couponId ? (
                        <Link to={`/admin/events/${booking.eventSlug}/coupons/${booking.couponId}`}>
                            {booking.couponTitle ?? booking.couponId}
                        </Link>
                    ) : (
                        "no coupon"
                    )}
                </TableCell>
            </TableRow>,
            <TableRow key="actual-price">
                <TableCell>Actual Price</TableCell>
                <TableCell>&pound;{booking.price.toFixed(2)}</TableCell>
            </TableRow>
        );
        if (booking.latestCharge) {
            additionalRows.push(
                <TableRow key="stripe-status">
                    <TableCell>Stripe Status</TableCell>
                    <TableCell>{booking.latestCharge.status}</TableCell>
                </TableRow>,
                <TableRow key="stripe-payment">
                    <TableCell>Stripe Payment</TableCell>
                    <TableCell>
                        <a href={booking.latestCharge.link}>{booking.latestCharge.id}</a>
                    </TableCell>
                </TableRow>
            );
            if (booking.latestCharge.receiptUrl) {
                additionalRows.push(
                    <TableRow key="stripe-receipt">
                        <TableCell>Stripe Receipt</TableCell>
                        <TableCell>
                            {" "}
                            <a href={booking.latestCharge.receiptUrl}>{booking.latestCharge.receiptUrl}</a>
                        </TableCell>
                    </TableRow>
                );
            }
        }
    } else if (booking.eventOptionType === "subscription" && booking.subscription) {
        additionalRows.push(
            <TableRow>
                <TableCell>Stipe Status</TableCell>
                <TableCell>
                    {booking.subscription.status} - current period ends: {showDateAndFriendlyDate(booking.subscription.currentPeriodEnd)}
                </TableCell>
            </TableRow>,
            <TableRow>
                <TableCell>Stripe Subscription</TableCell>
                <TableCell>
                    <a href={booking.subscription.link}>{booking.subscription.id}</a>
                </TableCell>
            </TableRow>
        );
    }
    const typeSpecificPostTableContent =
        booking.eventOptionType === "subscription" ? (
            <>
                <h2>Invoices</h2>
                {booking.subscription?.invoices.length === 0 ? (
                    <>No invoices</>
                ) : (
                    <>
                        <Table>
                            <TableHeader>
                                <TableHeaderCell>Invoice</TableHeaderCell>
                                <TableHeaderCell>Date</TableHeaderCell>
                                <TableHeaderCell>Amount</TableHeaderCell>
                                <TableHeaderCell>Status</TableHeaderCell>
                            </TableHeader>
                            <TableBody>
                                {booking.subscription?.invoices.map((invoice) => (
                                    <TableRow key={invoice.id}>
                                        <TableCell>
                                            <a href={invoice.link}>{invoice.id}</a>
                                        </TableCell>
                                        <TableCell>{showDateAndFriendlyDate(invoice.createdDate)}</TableCell>
                                        <TableCell>&pound;{invoice.amount.toFixed(2)}</TableCell>
                                        <TableCell>{invoice.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                )}
            </>
        ) : (
            <></>
        );

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / <Link to="/admin/bookings">bookings</Link> / {bookingId}
            </h1>
            <Table className="table">
                <TableBody>
                    <TableRow>
                        <TableCell>Booking Ref</TableCell>
                        <TableCell>{booking.id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Event</TableCell>
                        <TableCell>
                            <TableCellLayout>
                                <Link to={`/admin/events/${booking.eventSlug}`}>{booking.eventTitle}</Link>
                                <br />(
                                <Link to={`/admin/events/${booking.eventSlug}/${booking.eventOptionId}`}>{booking.eventOptionTitle}</Link>)
                            </TableCellLayout>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>
                            <Link to={`/admin/customers/${booking.customerId}`}>{booking.customerName}</Link>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{booking.customerEmail}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Booking Date</TableCell>
                        <TableCell>
                            {booking.bookingDate.toLocaleString()} {showFriendlyDate(booking.bookingDate)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Booking Site Status</TableCell>
                        <TableCell>
                            {booking.status}{" "}
                            {booking.statusReason && (
                                <>
                                    <br />
                                    {`(${booking.statusReason})`}
                                </>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>{booking.eventOptionType}</TableCell>
                    </TableRow>
                    {additionalRows}
                </TableBody>
            </Table>

            <div>
                <Link to={`/admin/audit-entries?bookingId=${booking.id}`}>Go to audit entries...</Link>
            </div>

            {typeSpecificPostTableContent}
        </>
    );
};
