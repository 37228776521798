import { useEffect, useState } from "react";
import { AuditEntry, PagedResult } from "../../models/Admin";
import {
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableCell,
    TableBody,
    TableCellLayout,
    TagGroup,
    Tag,
    TagGroupProps,
    Button,
} from "@fluentui/react-components";
import { Filter32Filled } from "@fluentui/react-icons";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as api from "../../utils/api";
import { getHumanFriendlyDelta } from "../../utils/friendlyDates";

function buildQueryString(
    pageIndex: number,
    pageSize: number,
    filters: { eventId: string | null; customerId: string | null; bookingId: string | null }
) {
    const parts = [];
    parts.push(`pageIndex=${pageIndex}`);
    parts.push(`pageSize=${pageSize}`);
    if (filters.eventId) {
        parts.push(`eventId=${filters.eventId}`);
    }
    if (filters.customerId) {
        parts.push(`customerId=${filters.customerId}`);
    }
    if (filters.bookingId) {
        parts.push(`bookingId=${filters.bookingId}`);
    }
    return `?${parts.join("&")}`;
}
export const AdminAuditEntryList = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [entries, setEntries] = useState<AuditEntry[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const pageSize = 25;
    const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);

    const eventId = searchParams.get("eventId");
    const customerId = searchParams.get("customerId");
    const bookingId = searchParams.get("bookingId");

    const entriesQueryString = buildQueryString(pageIndex, pageSize, { eventId, customerId, bookingId });
    const totalPages = Math.ceil(totalRows / pageSize);

    useEffect(() => {
        async function execute() {
            try {
                setIsCallingApi(true);
                setError(null);
                const result = await api.get<PagedResult<AuditEntry>>(`api/admin/audit-entries${entriesQueryString}`, {
                    jsonReviver: api.dateReviver,
                });
                setEntries(result.data);
                setTotalRows(result.total);
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsCallingApi(false);
            }
        }
        execute();
    }, [entriesQueryString]);

    if (error) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / audit entries
                </h1>
                <div>Error: {error.message}</div>
            </>
        );
    }
    if (isCallingApi) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / audit entries
                </h1>
                <div>loading...</div>
            </>
        );
    }
    function showFriendlyDate(date: Date) {
        const friendlyDate = getHumanFriendlyDelta(date);
        if (friendlyDate) {
            return <>({friendlyDate})</>;
        }
        return <></>;
    }

    const dismisTag: TagGroupProps["onDismiss"] = (_e, { value }) => {
        searchParams.delete(value);
        const query = searchParams.toString() === "" ? "" : `?${searchParams.toString()}`;
        setPageIndex(0);
        navigate(`/admin/audit-entries${query}`);
    };

    const addFilter = (filterOn: string, filterValue: string) => {
        searchParams.set(filterOn, filterValue);
        const query = searchParams.toString() === "" ? "" : `?${searchParams.toString()}`;
        setPageIndex(0);
        navigate(`/admin/audit-entries${query}`);
    };

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / audit entries
            </h1>
            <div>
                {eventId != null || customerId != null || bookingId != null ? (
                    <>
                        <h2>Filters</h2>
                        <TagGroup onDismiss={dismisTag}>
                            {eventId && (
                                <Tag dismissible value={"eventId"} dismissIcon={{ "aria-label": "remove" }}>
                                    {`eventId=${eventId}`}
                                </Tag>
                            )}
                            {bookingId && (
                                <Tag dismissible value={"bookingId"} dismissIcon={{ "aria-label": "remove" }}>
                                    {`bookingId=${bookingId}`}
                                </Tag>
                            )}
                            {customerId && (
                                <Tag dismissible value={"customerId"} dismissIcon={{ "aria-label": "remove" }}>
                                    {`customerId=${customerId}`}
                                </Tag>
                            )}
                        </TagGroup>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <h2>Audit Entries</h2>
            <Table className="table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Date</TableHeaderCell>
                        <TableHeaderCell>CustomerId</TableHeaderCell>
                        <TableHeaderCell>Event</TableHeaderCell>
                        <TableHeaderCell>Booking</TableHeaderCell>
                        <TableHeaderCell>Entry Type</TableHeaderCell>
                        <TableHeaderCell>Details</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {entries?.map((entry) => (
                        <TableRow key={entry.id}>
                            <TableCell>
                                <TableCellLayout appearance="primary">
                                    {entry.entryDate.toLocaleString()} {showFriendlyDate(entry.entryDate)}
                                </TableCellLayout>
                            </TableCell>
                            {entry.customer && (
                                <>
                                    <Link to={`/admin/customers/${entry.customer.id}`}>{entry.customer.email}</Link>
                                    {!customerId && (
                                        <Button
                                            icon={<Filter32Filled />}
                                            title="Filter by customer"
                                            onClick={() => addFilter("customerId", entry.customer!.id)}
                                        ></Button>
                                    )}
                                </>
                            )}
                            <TableCell>
                                {entry.event && (
                                    <>
                                        <Link to={`/admin/events/${entry.event.slug}`}>{entry.event.title}</Link>&nbsp;
                                        {!eventId && (
                                            <Button
                                                icon={<Filter32Filled />}
                                                title="Filter by event"
                                                onClick={() => addFilter("eventId", entry.event!.id.toString())}
                                            ></Button>
                                        )}
                                    </>
                                )}
                            </TableCell>
                            <TableCell>
                                {entry.bookingId && (
                                    <>
                                        <Link to={`/admin/bookings/${entry.bookingId}`}>{entry.bookingId}</Link>&nbsp;
                                        {!bookingId && (
                                            <Button
                                                icon={<Filter32Filled />}
                                                title="Filter by booking"
                                                onClick={() => addFilter("bookingId", entry.bookingId!)}
                                            ></Button>
                                        )}
                                    </>
                                )}
                            </TableCell>
                            <TableCell>{entry.entryType}</TableCell>
                            <TableCell>{entry.details}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <div className="pager-controls">
                <Button
                    onClick={() => {
                        if (pageIndex > 0) {
                            setPageIndex(pageIndex - 1);
                        }
                    }}
                    disabled={pageIndex === 0}
                >
                    &lt; Previous
                </Button>
                <span className="current-page">
                    Page {pageIndex + 1} of {totalPages}
                </span>
                <Button
                    onClick={() => {
                        if (pageIndex < totalPages - 1) {
                            setPageIndex(pageIndex + 1);
                        }
                    }}
                    disabled={pageIndex >= totalPages - 1}
                >
                    Next &gt;
                </Button>
            </div>
        </>
    );
};
