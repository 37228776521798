import React, { useEffect, useRef, useState } from "react";

import * as TinyMDE from "tiny-markdown-editor";
import "../../node_modules/tiny-markdown-editor/dist/tiny-mde.css";
// https://github.com/jefago/tiny-markdown-editor/pull/55

// Built-in defaults:
// const defaultCommandBarCommands = ["bold","italic","strikethrough","|","code","|","h1","h2","|","ul","ol","|","blockquote","hr","|","insertLink","insertImage",];
const defaultCommandBarCommands: TinyMDE.CommandBarCommand[] = [
    "bold",
    "italic",
    "strikethrough",
    "|",
    "code",
    "|",
    { name: "h1", title: "h1", innerHTML: "<b>H1</b>" },
    { name: "h2", title: "h2", innerHTML: "<b>H2</b>" },
    {
        name: "h3",
        title: "h3",
        innerHTML: "<b>H3</b>",
        action: "h3" as TinyMDE.EditorCommandName,
    },
    "|",
    "ul",
    "ol",
    "|",
    "blockquote",
    "hr",
    "|",
    "insertLink",
    "insertImage",
];

export function MarkdownEditor(params: {
    value?: string;
    editorClassName?: string;
    commandBarCommands?: TinyMDE.CommandBarCommand[];
    onChange?: (data: { value: string }) => void; // make an event type with the content
}) {
    const commandBarRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    // const editorRef = useRef(new TinyMDE.Editor({ element: textareaRef.current! }));
    const [editor, setEditor] = useState<TinyMDE.Editor | null>(null);
    const [initialised, setInitialised] = useState(false);

    useEffect(() => {
        if (initialised && textareaRef.current && editor) {
            // Update the content if it doesn't match
            // (conditional update to avoid the cursor jumping while typing!)
            if (params.value !== editor.getContent()) {
                (editor as any).setContent(params.value);
            }
            return;
        }
        // Check if we have refs and haven't initialised yet
        if (commandBarRef.current && textareaRef.current && !initialised) {
            // when running in dev with Strict Mode, React invokes us twice
            // use a data attribute on the textarea to detect this!
            if (textareaRef.current.getAttribute("data-tiny-mde") === "true") {
                // already have an editor
                return;
            }
            const editorLocal = new TinyMDE.Editor({
                element: textareaRef.current!,
                content: params.value,
            });
            textareaRef.current.setAttribute("data-tiny-mde", "true");
            editorLocal?.addEventListener("change", (e) => {
                params.onChange?.({ value: e.content });
            });
            new TinyMDE.CommandBar({
                editor: editorLocal,
                element: commandBarRef.current,
                commands:
                    params.commandBarCommands ?? defaultCommandBarCommands,
            });
            setEditor(editorLocal);
            setInitialised(true);
        }
    }, [setEditor, initialised, setInitialised, editor, params]);

    return (
        <>
            <div ref={commandBarRef}/>
            <textarea ref={textareaRef} className={params.editorClassName}/>
        </>
    );
}
