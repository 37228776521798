import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ResourcePack, ResourcePackHeading } from "../../models/Admin";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "../../hooks/useBoolean";
import { useDialogStyles } from "../../hooks/useDialogStyles";
import {
    Field,
    Input,
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
} from "@fluentui/react-components";
import * as api from "../../utils/api";
import { ApiValidationError, getEntityWithValidation } from "../../models/ValidatedEntity";

type ResourcePackHeadingEdit = Omit<ResourcePackHeading, "id" | "packId">;

export const AdminResourcePackHeadingEdit = () => {
    const { packId, headingId } = useParams() as { packId: string; headingId: string };
    const navigate = useNavigate();
    const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
        useBoolean(false);
    const [isDeleteConfirmationDialogOpen, { setTrue: showDeleteConfirmationDialog, setFalse: hideDeleteConfirmationDialog }] =
        useBoolean(false);
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [packName, setPackName] = useState<string | null>(null);
    const [headingName, setHeadingName] = useState<string | null>(null);
    const [resourcePackHeading, setResourcePackHeading] = useState<ResourcePackHeading | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);

    const [showSavedBanner, setShowSavedBanner] = useState(false);

    useEffect(() => {
        async function execute() {
            setActivityMessage("loading...");
            try {
                const resourcePack = await api.get<ResourcePack>(`api/admin/resource-packs/${packId}`);
                const resourcePackHeading = await api.get<ResourcePackHeading>(`api/admin/resource-packs/${packId}/headings/${headingId}`);
                setResourcePackHeading(resourcePackHeading);
                setPackName(resourcePack?.name ?? null);
                setHeadingName(resourcePackHeading?.name ?? null);
            } catch (e) {
                setError(e as Error);
            } finally {
                setActivityMessage(null);
            }
        }
        execute();
    }, [packId, headingId]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        if (!resourcePackHeading) {
            throw new Error("Resource not loaded");
        }
        setActivityMessage("saving...");
        try {
            const { id, packId: _, ...resourcePackHeadingUpdate } = resourcePackHeading;
            const response = await api.put<ResourcePackHeadingEdit, ResourcePackHeading>(
                `api/admin/resource-packs/${packId}/headings/${headingId}`,
                resourcePackHeadingUpdate
            );
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                setValidationErrors([]);
                setResourcePackHeading(response);
                setShowSavedBanner(true);
                setTimeout(() => setShowSavedBanner(false), 1000);
            }
        } catch (e) {
            setError(e as Error);
        } finally {
            setActivityMessage(null);
        }
    };
    const deleteEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        hideDeleteConfirmationDialog();
        setActivityMessage("deleting...");
        await api.delete(`api/admin/resource-packs/${packId}/headings/${headingId}`);
        navigate(`/admin/resource-packs/${packId}`);
    };

    const entityWithValidation = resourcePackHeading && getEntityWithValidation(resourcePackHeading, validationErrors);

    const styles = useDialogStyles();

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / <Link to="/admin/resource-packs">resource packs</Link> /{" "}
                <Link to={`/admin/resource-packs/${packId}`}>{packName}</Link> / headings / {headingName}
            </h1>
            {showSavedBanner && <div className={styles.savedBanner}>Saved!</div>}
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>Error: {error.message}</div>}

            <div className={styles.root}>
                {entityWithValidation && (
                    <>
                        {entityWithValidation.entityMessage && <div>Errors: {entityWithValidation.entityMessage}</div>}
                        <Field
                            label="Name"
                            hint="Name of the heading"
                            orientation="vertical"
                            required={true}
                            validationMessage={entityWithValidation.propertyMessages.name}
                        >
                            <Input
                                defaultValue={resourcePackHeading.name}
                                onChange={(_, data) => setResourcePackHeading({ ...resourcePackHeading, name: data.value })}
                            />
                        </Field>
                        <Field
                            label="Order"
                            hint="Sort order for the heading - headings are sorted by their order value (lower numbers first)"
                            orientation="vertical"
                            required={true}
                            validationMessage={entityWithValidation.propertyMessages.order}
                        >
                            <Input
                                defaultValue={resourcePackHeading.order.toString()}
                                type="number"
                                onChange={(_, data) => setResourcePackHeading({ ...resourcePackHeading, order: parseInt(data.value) })}
                            />
                        </Field>
                        <div className={styles.actions}>
                            <Button appearance="primary" onClick={saveEntity}>
                                Save
                            </Button>
                            <Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
                                Cancel
                            </Button>
                            <Button appearance="secondary" className={styles.dangerButton} onClick={showDeleteConfirmationDialog}>
                                Delete
                            </Button>
                            <Dialog open={isDiscardConfirmationDialogOpen}>
                                <DialogSurface>
                                    <DialogBody>
                                        <DialogTitle>Discard changes?</DialogTitle>
                                        <DialogContent>Are you sure you want to discard any changes?</DialogContent>
                                        <DialogActions>
                                            <Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
                                                Cancel
                                            </Button>
                                            <Button appearance="primary" onClick={() => navigate(`/admin/resource-packs/${packId}`)}>
                                                Discard
                                            </Button>
                                        </DialogActions>
                                    </DialogBody>
                                </DialogSurface>
                            </Dialog>
                            <Dialog open={isDeleteConfirmationDialogOpen}>
                                <DialogSurface>
                                    <DialogBody>
                                        <DialogTitle>Delete Heading?</DialogTitle>
                                        <DialogContent>
                                            WARNING: deleting cannot be undone. Are you sure you want to delete this heading?
                                        </DialogContent>
                                        <DialogActions>
                                            <Button appearance="secondary" onClick={hideDeleteConfirmationDialog}>
                                                Cancel
                                            </Button>
                                            <Button appearance="primary" className={styles.dangerButton} onClick={deleteEntity}>
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </DialogBody>
                                </DialogSurface>
                            </Dialog>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
