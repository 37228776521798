import { useEffect, useState } from "react";
import { EventSummary } from "../../models/Admin";
import {
    Button,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableCell,
    TableBody,
    TableCellLayout,
    Checkbox,
} from "@fluentui/react-components";
import { Link, useNavigate } from "react-router-dom";
import * as api from "../../utils/api";

export const AdminEventList = () => {
    const navigate = useNavigate();
    const [includeArchived, setIncludeArchived] = useState(false);
    const [events, setEvents] = useState<EventSummary[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);

    useEffect(() => {
        async function execute() {
            try {
                setIsCallingApi(true);
                setError(null);
                setEvents(await api.get<EventSummary[]>(`api/admin/events?includeArchived=${includeArchived}`));
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsCallingApi(false);
            }
        }
        execute();
    }, [includeArchived]);

    if (error) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / events
                </h1>
                <div>Error: {error.message}</div>
            </>
        );
    }
    if (isCallingApi) {
        return (
            <>
                <h1>
                    <Link to="/admin">admin</Link> / events
                </h1>
                <div>loading...</div>
            </>
        );
    }
    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / events
            </h1>
            <div>
                <Checkbox
                    label="Include archived events?"
                    defaultChecked={includeArchived}
                    onChange={(_, data) => setIncludeArchived(!!data.checked)}
                />
            </div>
            <Table className="table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Slug</TableHeaderCell>
                        <TableHeaderCell>Title</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {events?.map((event) => (
                        <TableRow key={event.id}>
                            <TableCell>
                                <TableCellLayout appearance="primary">
                                    <Link to={`/admin/events/${event.slug}`}>{event.slug}</Link>
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>{event.title}</TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <Link to={`/admin/bookings?eventId=${event.id}`}>bookings...</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button onClick={() => navigate("/admin/events/$new")}>New Event</Button>
        </>
    );
};
